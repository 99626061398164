exports.components = {
  "component---src-pages-black-js": () => import("./../../../src/pages/black.js" /* webpackChunkName: "component---src-pages-black-js" */),
  "component---src-pages-box-tsx": () => import("./../../../src/pages/box.tsx" /* webpackChunkName: "component---src-pages-box-tsx" */),
  "component---src-pages-dieta-js": () => import("./../../../src/pages/dieta.js" /* webpackChunkName: "component---src-pages-dieta-js" */),
  "component---src-pages-exclusive-renewal-js": () => import("./../../../src/pages/exclusive-renewal.js" /* webpackChunkName: "component---src-pages-exclusive-renewal-js" */),
  "component---src-pages-healthy-mom-js": () => import("./../../../src/pages/healthy-mom.js" /* webpackChunkName: "component---src-pages-healthy-mom-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kalendarz-js": () => import("./../../../src/pages/kalendarz.js" /* webpackChunkName: "component---src-pages-kalendarz-js" */),
  "component---src-pages-lifetime-js": () => import("./../../../src/pages/lifetime.js" /* webpackChunkName: "component---src-pages-lifetime-js" */),
  "component---src-pages-live-preview-details-tsx": () => import("./../../../src/pages/live-preview-details.tsx" /* webpackChunkName: "component---src-pages-live-preview-details-tsx" */),
  "component---src-pages-live-preview-tsx": () => import("./../../../src/pages/live-preview.tsx" /* webpackChunkName: "component---src-pages-live-preview-tsx" */),
  "component---src-pages-mariola-js": () => import("./../../../src/pages/mariola.js" /* webpackChunkName: "component---src-pages-mariola-js" */),
  "component---src-pages-metamorfoza-tsx": () => import("./../../../src/pages/metamorfoza.tsx" /* webpackChunkName: "component---src-pages-metamorfoza-tsx" */),
  "component---src-pages-nowyrok-js": () => import("./../../../src/pages/nowyrok.js" /* webpackChunkName: "component---src-pages-nowyrok-js" */),
  "component---src-pages-nowyrok-tiktok-js": () => import("./../../../src/pages/nowyrok-tiktok.js" /* webpackChunkName: "component---src-pages-nowyrok-tiktok-js" */),
  "component---src-pages-ostatniaszansa-js": () => import("./../../../src/pages/ostatniaszansa.js" /* webpackChunkName: "component---src-pages-ostatniaszansa-js" */),
  "component---src-pages-piramida-js": () => import("./../../../src/pages/piramida.js" /* webpackChunkName: "component---src-pages-piramida-js" */),
  "component---src-pages-poznaj-app-js": () => import("./../../../src/pages/poznaj-app.js" /* webpackChunkName: "component---src-pages-poznaj-app-js" */),
  "component---src-pages-rodo-tsx": () => import("./../../../src/pages/rodo.tsx" /* webpackChunkName: "component---src-pages-rodo-tsx" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-sprobuj-js": () => import("./../../../src/pages/sprobuj.js" /* webpackChunkName: "component---src-pages-sprobuj-js" */),
  "component---src-pages-szybciej-js": () => import("./../../../src/pages/szybciej.js" /* webpackChunkName: "component---src-pages-szybciej-js" */),
  "component---src-pages-szybko-js": () => import("./../../../src/pages/szybko.js" /* webpackChunkName: "component---src-pages-szybko-js" */),
  "component---src-pages-tiktok-black-js": () => import("./../../../src/pages/tiktok-black.js" /* webpackChunkName: "component---src-pages-tiktok-black-js" */),
  "component---src-pages-tiktok-js": () => import("./../../../src/pages/tiktok.js" /* webpackChunkName: "component---src-pages-tiktok-js" */),
  "component---src-pages-treningi-js": () => import("./../../../src/pages/treningi.js" /* webpackChunkName: "component---src-pages-treningi-js" */),
  "component---src-pages-tylko-teraz-tsx": () => import("./../../../src/pages/tylko-teraz.tsx" /* webpackChunkName: "component---src-pages-tylko-teraz-tsx" */),
  "component---src-pages-update-needed-js": () => import("./../../../src/pages/update-needed.js" /* webpackChunkName: "component---src-pages-update-needed-js" */),
  "component---src-pages-wyzwanie-form-tsx": () => import("./../../../src/pages/wyzwanie-form.tsx" /* webpackChunkName: "component---src-pages-wyzwanie-form-tsx" */),
  "component---src-pages-yoga-js": () => import("./../../../src/pages/yoga.js" /* webpackChunkName: "component---src-pages-yoga-js" */),
  "component---src-templates-page-builder-js": () => import("./../../../src/templates/pageBuilder.js" /* webpackChunkName: "component---src-templates-page-builder-js" */)
}

